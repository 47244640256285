// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  api: 'https://admin.feature.nspace.narbutas.com/api/',
  sentryDSN: 'https://339d4d1fd76c44a5b60e9a9dcebb283a@o485579.ingest.us.sentry.io/5947445',
  sentryTracesSampleRate: 0,
  release: '9.10.0',
  hidePrices: false,
  gofigure: '//gofigure.feature.nspace.narbutas.com',
  xdebugKey: 'VSCODE',
  gitHash: 'd767880e4232ce932eccebf5dbfea2ac8beea2c6',
  marketingMaterialsURL: '',
  serviceDeskURL: 'https://narbutas.atlassian.net/servicedesk/customer/portal/42/',
  imageResizeURL: 'https://imageresize.feature.nspace.narbutas.com/_/',
};
