import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageStoreHandlerService } from './core/services/language/language-store-handler.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'blockadblock';
import { DetectedAdblockerModalComponent } from './ui-elements/detected-adblocker-modal/detected-adblocker-modal/detected-adblocker-modal.component';
import { GenericModalTypes } from './ui-elements/generic-modal/generic-modal-types';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from './core/services/user/user.service';
import { SwUpdate } from '@angular/service-worker';
import { UpdateService } from './update.service';
import { BrowserSupportService } from './shared/services/browser-support/browser-support.service';
import { TreeService } from './core/tree/tree.service';
import {DOCUMENT} from "@angular/common";
import { TermsService } from './terms/terms.service';
import { DEFAULT_APP_LANGUAGE } from './core/constants/language.constants';
import { take } from 'rxjs';
import { MaintenanceService } from './maintenance/maintenance.service';
import { isMobile } from './core/util/feature-detection.helper';
import { DEFAULT_MOBILE_ROUTE, MOBILE_READY_ROUTES } from './core/enums/route-types.enum';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from './core/constants/breakpoints.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {
  public secondLevelNavVisible: boolean;
  public loggedIn: boolean;
  public isMobile: boolean;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private termsService: TermsService,
    private languageService: LanguageStoreHandlerService,
    private modalService: NgbModal,
    private router: Router,
    private userService: UserService,
    private updateService: UpdateService,
    private swUpdate: SwUpdate,
    private browserSupport: BrowserSupportService,
    private treeService: TreeService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private maintenanceService: MaintenanceService,
    private breakpointObserver: BreakpointObserver,
  ) {
    // show nice page about browser support for all IE browsers
    browserSupport.browserIsSupported$.subscribe(supported => {
      if (!supported) {
        router.navigate(['/unsupported-browser']);
      }
    })

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Do a soft redirect to new domain for dev env
        if (0
          || this.document.location.hostname.startsWith('dev.pricelist7')
          || this.document.location.hostname.startsWith('pricelist7')
        ) {
          this.document.location.hostname = this.document.location.hostname.replace('pricelist7', 'nspace');
          return;
        }

        // force SW update check on route change
        if (swUpdate.isEnabled) {
          swUpdate.checkForUpdate();
        }

        if (isMobile() && event.urlAfterRedirects !== DEFAULT_MOBILE_ROUTE) {
          const isWideScreen = this.breakpointObserver.isMatched(`(min-width: ${BREAKPOINTS.XL})`);
          if (!MOBILE_READY_ROUTES.some((route) => event.urlAfterRedirects.indexOf(`/${route}`) > -1) && !isWideScreen) {
            this.router.navigate([DEFAULT_MOBILE_ROUTE]);

            return;
          }
        }

        // @ts-ignore: global code
        window.dataLayer = window.dataLayer || [];

        this.userService.getUser().subscribe(user => {
          if (user) {
            // @ts-ignore: global window.dataLayer
            window.dataLayer.push({
              event: 'routeView',
              url: event.urlAfterRedirects || window.location.href,
              company: user.companyName || 'none',
              user_id: user.id,
              role: user.role.name,
              ax: user.axClientCode || 'none',
            });
          }
        });

        const params = this.route.firstChild?.snapshot.params;

        // Set language from URL
        if (params && params.language) {
          this.languageService.getAll().pipe(take(1)).subscribe((languages) => {
            // Removes language path param while assuming that it is first path param
            const pathWithoutLang = this.router.url.split('/').slice(2);
            const navigationUrl = '/' + pathWithoutLang.join('/');

            const lang = languages.find((language) => language.short === params.language);
            this.languageService.switch(lang).subscribe(() => {
              // navigate only then new translations file has been loaded
              this.router.navigateByUrl(navigationUrl, { replaceUrl: true });
            });
          });
        }
      }
    });
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(DEFAULT_APP_LANGUAGE);

    languageService.fetch().subscribe(languages => {
      translate.addLangs(languages.map(lang => lang.short));
    });

    const blockAdBlock = new (<any>window).BlockAdBlock({
      checkOnLoad: true,
      resetOnEnd: true,
    });

    blockAdBlock.onDetected(() => {
      modalService.open(DetectedAdblockerModalComponent, {
        windowClass: `${GenericModalTypes.GREEN}`,
        size: 'sm',
        backdrop: 'static',
        keyboard: false,
      });
    });

    this.treeService.getState().subscribe((val) => {
      this.secondLevelNavVisible = val.first?.children?.length > 0;
    });
  }

  ngOnInit(): void {
    this.isMobile = isMobile();

    this.authService.authenticatedObservable().subscribe(user => {
      if (user) {
        this.maintenanceService.fetchPlan();
      }

      this.loggedIn = user;
      this.changeDetectorRef.detectChanges();
    });
  }
}
